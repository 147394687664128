import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { adminRoot } from 'constants/defaultValues';
import { setCurrentUser } from 'helpers/Utils';
import axios from 'axios';

import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  UPDATE_USER_PROFILE,
} from '../contants';

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  updateUserProfileSuccess,
  updateUserProfileError,
} from './actions';

// Helper function to handle API errors
const handleError = (error, defaultMessage) => {
  return error.response?.data?.errorDetails || defaultMessage;
};

// Async functions
const loginWithEmailPasswordAsync = async (email, password) => {
  const response = await axios.post(`https://wefarmers.me/api/login_User`, {
    email,
    password,
  });
  return response.data;
};

const loginWithTokenAsync = async (token) => {
  const response = await axios.post(`https://wefarmers.me/api/VerifierToken`, {
    token,
  });
  return response.data;
};

const registerWithEmailPasswordAsync = async (email, password, name) => {
  const response = await axios.post(
    `https://wefarmers.me/api/Inscription_User`,
    { username: name, email, password }
  );
  return response.data;
};

const logoutAsync = async (history) => {
  history.push(adminRoot);
};

const forgotPasswordAsync = async (email) => {
  // await auth.sendPasswordResetEmail(email);
  console.log(email);
};

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  // await auth.confirmPasswordReset(resetPasswordCode, newPassword);
  console.log(resetPasswordCode, newPassword);
};

// Sagas
function* loginWithEmailPassword({ payload }) {
  const { email, password, token } = payload.user;
  // const { history } = payload;
  try {
    const response = token
      ? yield call(loginWithTokenAsync, token)
      : yield call(loginWithEmailPasswordAsync, email, password);

    const { result, error } = response;

    if (result) {
      const userData = {
        uid: result.id,
        email: result.email,
        name: result.username || 'userName',
        token: result.remember_token,
        role: result.is_admin,
        image: result.thumbnail,
      };
      setCurrentUser(userData);
      yield put(loginUserSuccess(userData));
      // history.push(adminRoot);
      window.location.href = adminRoot;
    } else {
      yield put(loginUserError(error || 'Login failed'));
    }
  } catch (error) {
    yield put(
      loginUserError(handleError(error, 'An error occurred during login'))
    );
  }
}
function* updateUserProfile({ payload }) {
  try {
    if (payload) {
      const userData = {
        uid: payload.id,
        email: payload.email,
        name: payload.username || 'userName',
        token: payload.remember_token,
        role: payload.is_admin,
        image: payload.thumbnail,
      };
      setCurrentUser(userData);
      yield put(updateUserProfileSuccess(userData));
    } else {
      yield put(updateUserProfileError('Profile update failed'));
    }
  } catch (error) {
    yield put(
      updateUserProfileError(
        handleError(error, 'An error occurred during profile update')
      )
    );
  }
}

function* registerWithEmailPassword({ payload }) {
  const { email, password, name } = payload.user;
  const { history } = payload;

  try {
    const response = yield call(
      registerWithEmailPasswordAsync,
      email,
      password,
      name
    );
    const { resultat } = response;

    if (resultat) {
      const userData = {
        uid: resultat.id,
        email: resultat.email,
        name: resultat.username || 'userName',
        token: resultat.remember_token,
        role: resultat.is_admin,
      };
      setCurrentUser(userData);
      yield put(registerUserSuccess(userData));
      history.push('/user/login');
    } else {
      yield put(registerUserError('Registration failed'));
    }
  } catch (error) {
    yield put(
      registerUserError(
        handleError(error, 'An error occurred during registration')
      )
    );
  }
}

function* logout({ payload }) {
  const { history } = payload;
  setCurrentUser(null);
  if (history) {
    yield call(logoutAsync, history);
  }
  window.location.href = `/user/login/`;
}

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    yield call(forgotPasswordAsync, email);
    yield put(forgotPasswordSuccess('success'));
  } catch (error) {
    yield put(
      forgotPasswordError(
        handleError(error, 'Failed to send password reset email')
      )
    );
  }
}

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    yield call(resetPasswordAsync, resetPasswordCode, newPassword);
    yield put(resetPasswordSuccess('success'));
  } catch (error) {
    yield put(
      resetPasswordError(handleError(error, 'Failed to reset password'))
    );
  }
}

// Watchers
export function* watchUpdateProfile() {
  yield takeEvery(UPDATE_USER_PROFILE, updateUserProfile);
}
export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

// Root Saga
export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    fork(watchUpdateProfile),
  ]);
}

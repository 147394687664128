import axios from 'axios';
import { logoutUser } from 'redux/actions';
import { NotificationManager } from 'components/common/react-notifications';

const tokenMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    const actionsRequiringToken = [
      'TODO_GET_LIST',
      'TODO_ADD_NEW',
      'ASSISTANCE_GET_LIST',
      'TODO_DELETE',
    ]; // Add action types requiring token validation

    if (actionsRequiringToken.includes(action.type)) {
      const state = getState();
      const token = state.authUser?.currentUser?.token;

      if (!token) {
        dispatch(logoutUser());
        NotificationManager.error(
          'Session expired. Please log in again.',
          'Error',
          3000
        );
        return;
      }

      try {
        const response = await axios.post(
          'https://wefarmers.me/api/VerifierToken',
          {
            token,
          }
        );

        if (response.data?.result?.remember_token === token) {
          // Proceed with the original action
          // next(action);
        } else {
          dispatch(logoutUser());
          NotificationManager.error(
            'Invalid session token. Please log in again.',
            'Error',
            3000
          );
        }
      } catch (error) {
        console.error('Token verification failed:', error);
        dispatch(logoutUser());
        NotificationManager.error(
          'Session verification failed. Please log in again.',
          'Error',
          3000
        );
      }
    } else {
      // Pass through other actions
      next(action);
    }
  };

export default tokenMiddleware;

import { currentUser } from 'constants/defaultValues';
import {
  CHAT_GET_CONTACTS,
  CHAT_GET_CONTACTS_SUCCESS,
  CHAT_GET_CONTACTS_ERROR,
  CHAT_GET_CONVERSATIONS,
  CHAT_GET_CONVERSATIONS_SUCCESS,
  CHAT_GET_CONVERSATIONS_ERROR,
  CHAT_ADD_MESSAGE_TO_CONVERSATION,
  CHAT_CREATE_CONVERSATION,
  CHAT_SEARCH_CONTACT,
  CHAT_CHANGE_CONVERSATION,
  CHAT_GET_MESSAGES,
  CHAT_GET_MESSAGES_SUCCESS,
  CHAT_GET_MESSAGES_ERROR,
  UPDATE_UNSEEN_CONVERSATION_COUNT,
  MARK_CONVERSATION_AS_SEEN,
  SOCKET_UPDATE_CONVERSATION,
  // GET_MESSAGE_NOTIFICATION_SUCCESS,
} from '../contants';

const INIT_STATE = {
  allContacts: [],
  contacts: null,
  conversations: [],
  error: '',
  searchKeyword: '',
  loadingContacts: false,
  loadingConversations: false,
  loadingMessages: false,
  currentUser: null,
  selectedUser: null,
  currentConversation: [],
  selectedUserId: null,
  conversationMessages: [],
  nootSeenConversation: 0,
  reloadConv: false,
  notifMessage: [],
};
const countUnseenConversations = (conversations) =>
  conversations.filter(
    (conv) =>
      conv.seen === false && conv.recentuseraddid !== currentUser.uid.toString()
  ).length;

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHAT_GET_CONTACTS:
      return { ...state, loadingContacts: false };

    case CHAT_GET_CONTACTS_SUCCESS:
      return {
        ...state,
        loadingContacts: true,
        allContacts: action.payload.contacts,
        contacts: action.payload.contacts,
        currentUser: action.payload.currentUser,
      };

    case CHAT_GET_CONTACTS_ERROR:
      return { ...state, loadingContacts: false, error: action.payload };

    case CHAT_GET_CONVERSATIONS:
      return { ...state, loadingConversations: false };

    case CHAT_GET_CONVERSATIONS_SUCCESS: {
      const unseenCount = countUnseenConversations(
        action.payload.conversations
      );
      return {
        ...state,
        loadingConversations: true,
        conversations: action.payload.conversations.sort(
          (a, b) =>
            new Date(b.recentactivitydate) - new Date(a.recentactivitydate)
        ),
        selectedUserId: action.payload.selectedUser,
        nootSeenConversation: unseenCount,
      };
    }

    case CHAT_GET_CONVERSATIONS_ERROR:
      return { ...state, loadingConversations: true, error: action.payload };

    case CHAT_CHANGE_CONVERSATION: {
      const updatedConversations = state.conversations?.map((conv) =>
        conv?.id === action.payload.conversation?.id
          ? { ...conv, seen: true }
          : conv
      );
      const newUnseenCount = countUnseenConversations(updatedConversations);

      return {
        ...state,
        selectedUser: state.allContacts?.find(
          (x) => x.id === action.payload.userId
        ),
        currentConversation: action.payload.conversation,
        conversations: updatedConversations,
        nootSeenConversation: newUnseenCount, // update count
      };
    }

    case CHAT_ADD_MESSAGE_TO_CONVERSATION: {
      const updatedConversations = state.conversations.map((conv) =>
        conv.id === action.payload.conversationId
          ? {
              ...conv,
              recentuseraddid: action.payload.userId.toString(),
              recentactivitydate: new Date(),
              recentmessage: action.payload.messageContent,
            }
          : conv
      );
      return { ...state, conversations: updatedConversations };
    }
    case SOCKET_UPDATE_CONVERSATION: {
      const { message, updateSeenStatus } = action.payload;
      const updatedConversations = state.conversations.map((conv) =>
        conv.id === message.conversationId
          ? {
              ...conv,
              recentuseraddid: message.userId,
              recentactivitydate: message.dateadd,
              recentmessage: message.messagecontent,
              seen: updateSeenStatus,
            }
          : conv
      );
      return { ...state, conversations: updatedConversations };
    }

    case CHAT_CREATE_CONVERSATION:
      return { ...state };

    case CHAT_SEARCH_CONTACT:
      if (action.payload === '') {
        return { ...state, contacts: state.allContacts };
      }
      // eslint-disable-next-line no-case-declarations
      const keyword = action.payload.toLowerCase();
      // eslint-disable-next-line no-case-declarations
      const searchedContacts = state.allContacts?.filter(
        (item) => item.username.toLowerCase().indexOf(keyword) > -1
      );
      return {
        ...state,
        contacts: searchedContacts,
      };
    // messages condions handler
    case CHAT_GET_MESSAGES:
      return { ...state, loadingMessages: false };

    case CHAT_GET_MESSAGES_SUCCESS: {
      const sortedData = action.payload.sort(
        (a, b) => new Date(a.dateadd) - new Date(b.dateadd)
      );
      return {
        ...state,
        loadingMessages: true,
        conversationMessages: sortedData,
      };
    }
    case CHAT_GET_MESSAGES_ERROR:
      return { ...state, loadingMessages: false, error: action.payload };

    case UPDATE_UNSEEN_CONVERSATION_COUNT: {
      const { conv, contacts } = action.payload;
      const unseenConversations = conv;
      // Map conversations with associated contact information
      const updatedUnseenConversations = unseenConversations.map(
        (conversation) => {
          const sender = contacts?.find(
            (contact) => contact.id.toString() === conversation.recentuseraddid
          );

          if (!sender) {
            console.warn(
              `Contact not found for recentUser ID: ${conversation.recentUser}`
            );
            return { ...conversation, username: 'Unknown', userimage: null };
          }

          return {
            ...conversation,
            username: sender.username,
            userimage: sender.thumbnail,
            senderID: sender.id,
          };
        }
      );
      return {
        ...state,
        nootSeenConversation: unseenConversations.length,
        notifMessage: [...updatedUnseenConversations],
      };
    }

    case MARK_CONVERSATION_AS_SEEN: {
      const updatedConversations = state.conversations.map((conv) =>
        conv.id === action.payload ? { ...conv, seen: true } : conv
      );
      return {
        ...state,
        conversations: updatedConversations,
        nootSeenConversation: countUnseenConversations(updatedConversations),
      };
    }
    case 'RELOADE_CONVERSATION':
      return { ...state, reloadConv: !state.reloadConv };
    // case GET_MESSAGE_NOTIFICATION_SUCCESS: {
    //   return {
    //     ...state,
    //     notifMessage: [action.payload, ...state.notifMessage],
    //   };
    // }
    // case GET_MESSAGE_NOTIFICATION_SUCCESS: {
    //   const { conversationId, username, userimage } = action.payload;
    //   const conv = state.conversations?.find((x) => x.id === conversationId);
    //   console.log('firstcsq5465', conv);
    //   return {
    //     ...state,
    //     notifMessage: [{ ...conv, username, userimage, senderID }, ...state.notifMessage],
    //   };
    // }

    default:
      return { ...state };
  }
};

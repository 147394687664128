import {
  ASSISTANCE_GET_LIST,
  ASSISTANCE_GET_LIST_SUCCESS,
  ASSISTANCE_GET_LIST_ERROR,
  ASSISTANCE_GET_LIST_WITH_FILTER,
  ASSISTANCE_GET_LIST_WITH_ORDER,
  ASSISTANCE_GET_LIST_SEARCH,
  ASSISTANCE_ADD_ITEM,
  ASSISTANCE_ADD_ITEM_SUCCESS,
  ASSISTANCE_ADD_ITEM_ERROR,
  ASSISTANCE_SELECTED_ITEMS_CHANGE,
  ASSISTANCE_RESPONSES_GET_LIST,
  ASSISTANCE_GET_RESPONSES_LIST_SUCCESS,
  ASSISTANCE_GET_RESPONSES_LIST_ERROR,
  CATEYGORYS_GET_LIST,
  CATEYGORYS_GET_LIST_LIST_SUCCESS,
  CATEYGORYS_GET_LIST_LIST_ERROR,
  CATEYGORY_ADD_ITEM,
  CATEYGORY_ADD_SUCCESS,
  CATEYGORY_ADD_ERROR,
  ASSISTANCE_ADD_RESPONSE_ITEM_ERROR,
  ASSISTANCE_ADD_RESPONSE_ITEM_SUCCESS,
  ASSISTANCE_ADD_RESPONSE_ITEM,
} from '../contants';

const INIT_STATE = {
  allAssistanceItems: [],
  assistanceItems: [],
  allAssistanceResponses: null,
  assistanceResponses: null,
  currentUserAssistance: null,
  error: '',
  filter: {
    column: 'etat',
    value: 'Open',
  },
  searchKeyword: '',
  orderColumn: null,
  loaded: false,
  labels: [
    { label: 'EDUCATION', color: 'secondary' },
    { label: 'NEW FRAMEWORK', color: 'primary' },
    { label: 'PERSONAL', color: 'info' },
  ],
  orderColumns: [
    { column: 'title', label: 'Title' },
    { column: 'category', label: 'Category' },
    { column: 'status', label: 'Status' },
  ],
  categories: [],
  selectedItems: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ASSISTANCE_GET_LIST:
      return { ...state, loaded: false };
    case ASSISTANCE_RESPONSES_GET_LIST:
      return { ...state, loaded: false };
    case CATEYGORYS_GET_LIST:
      return { ...state, loaded: false };

    case ASSISTANCE_GET_LIST_SUCCESS: {
      const sortedData = action.payload.sort(
        (a, b) => new Date(b.dateadd) - new Date(a.dateadd)
      );
      return {
        ...state,
        loaded: true,
        allAssistanceItems: sortedData,
        assistanceItems: sortedData,
      };
    }
    case ASSISTANCE_GET_RESPONSES_LIST_SUCCESS: {
      const sortedData = action.payload.responses.sort(
        (a, b) => new Date(a.dateadd) - new Date(b.dateadd)
      );
      return {
        ...state,
        loaded: true,
        allAssistanceResponses: sortedData,
        assistanceResponses: sortedData,
        currentUserAssistance: action.payload.assistance,
      };
    }
    case CATEYGORYS_GET_LIST_LIST_SUCCESS:
      return {
        ...state,
        loaded: true,
        categories: action.payload,
      };

    case ASSISTANCE_GET_LIST_ERROR:
      return {
        ...state,
        loaded: true,
        error: action.payload,
        categories: state.categories,
      };
    case ASSISTANCE_GET_RESPONSES_LIST_ERROR:
      return { ...state, loaded: true, error: action.payload };
    case CATEYGORYS_GET_LIST_LIST_ERROR:
      return { ...state, loaded: true, error: action.payload };

    case ASSISTANCE_GET_LIST_WITH_FILTER:
      if (action.payload.column === '' || action.payload.value === '') {
        return {
          ...state,
          loaded: true,
          assistanceItems: state.allAssistanceItems,
          filter: null,
        };
      }
      if (action.payload.column === 'etat' && action.payload.value === 'Open') {
        const filteredItems = state.allAssistanceItems?.filter(
          (item) => item.etat !== 'Solved' && item.etat !== 'Closed'
        );
        return {
          ...state,
          loaded: true,
          assistanceItems: filteredItems,
          filter: null,
        };
      }
      // eslint-disable-next-line no-case-declarations
      const filteredItems = state.allAssistanceItems?.filter(
        (item) => item[action.payload.column] === action.payload.value
      );
      return {
        ...state,
        loaded: true,
        assistanceItems: filteredItems,
        filter: {
          column: action.payload.column,
          value: action.payload.value,
        },
      };

    case ASSISTANCE_GET_LIST_WITH_ORDER:
      if (action.payload === '') {
        return {
          ...state,
          loaded: true,
          assistanceItems: state.assistanceItems,
          orderColumn: null,
        };
      }
      // eslint-disable-next-line no-case-declarations
      const sortedItems = state.assistanceItems.sort((a, b) => {
        if (a[action.payload] < b[action.payload]) return -1;
        if (a[action.payload] > b[action.payload]) return 1;
        return 0;
      });
      return {
        ...state,
        loaded: true,
        assistanceItems: sortedItems,
        orderColumn: state.orderColumns.find(
          (x) => x.column === action.payload
        ),
      };

    case ASSISTANCE_GET_LIST_SEARCH:
      if (action.payload === '') {
        return { ...state, assistanceItems: state.allAssistanceItems };
      }
      // eslint-disable-next-line no-case-declarations
      const keyword = action.payload.toLowerCase();
      // eslint-disable-next-line no-case-declarations
      const searchItems = state.allAssistanceItems.filter(
        (item) =>
          item.title.toLowerCase().indexOf(keyword) > -1 ||
          item.detail.toLowerCase().indexOf(keyword) > -1 ||
          item.status.toLowerCase().indexOf(keyword) > -1 ||
          item.category.toLowerCase().indexOf(keyword) > -1 ||
          item.label.toLowerCase().indexOf(keyword) > -1
      );
      return {
        ...state,
        loaded: true,
        assistanceItems: searchItems,
        searchKeyword: action.payload,
      };

    case ASSISTANCE_ADD_ITEM:
      return { ...state, loaded: false };

    case ASSISTANCE_ADD_ITEM_SUCCESS:
      return {
        ...state,
        loaded: true,
        allAssistanceItems: action.payload,
        assistanceItems: action.payload,
      };

    case ASSISTANCE_ADD_ITEM_ERROR:
      return { ...state, loaded: true, error: action.payload };
    //
    case ASSISTANCE_ADD_RESPONSE_ITEM:
      return { ...state, loaded: false };

    case ASSISTANCE_ADD_RESPONSE_ITEM_SUCCESS: {
      const updatedResponses = [
        ...state.allAssistanceResponses,
        ...action.payload,
      ].sort((a, b) => new Date(a.dateadd) - new Date(b.dateadd));

      return {
        ...state,
        loaded: true,
        allAssistanceResponses: updatedResponses,
        assistanceResponses: updatedResponses,
      };
    }

    case ASSISTANCE_ADD_RESPONSE_ITEM_ERROR:
      return { ...state, loaded: true, error: action.payload };
    //
    case CATEYGORY_ADD_ITEM:
      return { ...state, loaded: false };

    case CATEYGORY_ADD_SUCCESS:
      return {
        ...state,
        loaded: true,
        categories: action.payload,
      };

    case CATEYGORY_ADD_ERROR:
      return { ...state, loaded: true, error: action.payload };
    //
    case ASSISTANCE_SELECTED_ITEMS_CHANGE:
      return { ...state, loaded: true, selectedItems: action.payload };
    default:
      return { ...state };
  }
};

import {
  TODO_GET_LIST,
  TODO_GET_LIST_SUCCESS,
  TODO_GET_LIST_ERROR,
  TODO_GET_LIST_WITH_FILTER,
  TODO_GET_LIST_WITH_ORDER,
  TODO_GET_LIST_SEARCH,
  TODO_ADD_ITEM,
  TODO_ADD_ITEM_SUCCESS,
  TODO_ADD_ITEM_ERROR,
  TODO_SELECTED_ITEMS_CHANGE,
  TICKETS_GET_RESPONSES_LIST_SUCCESS,
  TICKETS_GET_RESPONSES_LIST_ERROR,
  TICKETS_RESPONSES_GET_LIST,
  TIKETE_RESPONSE_ITEM,
  TIKETE_RESPONSE_ITEM_SUCCESS,
  TIKETE_RESPONSE_ITEM_ERROR,
  CATEYGORYS_GET_LIST,
  CATEYGORYS_GET_LIST_LIST_SUCCESS,
  CATEYGORYS_GET_LIST_LIST_ERROR,
  CATEYGORY_ADD_ITEM,
  CATEYGORY_ADD_SUCCESS,
  CATEYGORY_ADD_ERROR,
} from '../contants';

export const getTodoList = () => {
  return {
    type: TODO_GET_LIST,
  };
};

export const getTodoListSuccess = (items) => {
  return {
    type: TODO_GET_LIST_SUCCESS,
    payload: items,
  };
};

export const getTodoListError = (error) => {
  return {
    type: TODO_GET_LIST_ERROR,
    payload: error,
  };
};

// Tickets responses actions
export const TicketsResponseList = (item, history) => {
  return {
    type: TICKETS_RESPONSES_GET_LIST,
    payload: { item, history },
  };
};

export const getTicketsResponseSuccess = (items) => {
  return {
    type: TICKETS_GET_RESPONSES_LIST_SUCCESS,
    payload: items,
  };
};

export const getTicketsResponseError = (error) => {
  return {
    type: TICKETS_GET_RESPONSES_LIST_ERROR,
    payload: error,
  };
};

// Categories actions
export const getCategorysItems = () => {
  return {
    type: CATEYGORYS_GET_LIST,
  };
};

export const getCateygorysSuccess = (items) => {
  return {
    type: CATEYGORYS_GET_LIST_LIST_SUCCESS,
    payload: items,
  };
};

export const getCateygorysError = (error) => {
  return {
    type: CATEYGORYS_GET_LIST_LIST_ERROR,
    payload: error,
  };
};

export const addCategoryItem = (item) => {
  return {
    type: CATEYGORY_ADD_ITEM,
    payload: item,
  };
};

export const addcategorySuccess = (items) => {
  return {
    type: CATEYGORY_ADD_SUCCESS,
    payload: items,
  };
};

export const addcategoryError = (error) => {
  return {
    type: CATEYGORY_ADD_ERROR,
    payload: error,
  };
};

// Add Ticket Response
export const addTicketResponseItem = (item) => {
  return {
    type: TIKETE_RESPONSE_ITEM,
    payload: item,
  };
};

export const addTicketResponseSuccess = (items) => {
  return {
    type: TIKETE_RESPONSE_ITEM_SUCCESS,
    payload: items,
  };
};

export const addTicketResponseError = (error) => {
  return {
    type: TIKETE_RESPONSE_ITEM_ERROR,
    payload: error,
  };
};

// Filtered To-Do List
export const getTodoListWithFilter = (column, value) => {
  return {
    type: TODO_GET_LIST_WITH_FILTER,
    payload: { column, value },
  };
};

export const getTodoListWithOrder = (column) => {
  return {
    type: TODO_GET_LIST_WITH_ORDER,
    payload: column,
  };
};

export const getTodoListSearch = (keyword) => {
  return {
    type: TODO_GET_LIST_SEARCH,
    payload: keyword,
  };
};

// Add To-Do Item
export const addTodoItem = (item) => {
  return {
    type: TODO_ADD_ITEM,
    payload: item,
  };
};

export const addTodoItemSuccess = (items) => {
  return {
    type: TODO_ADD_ITEM_SUCCESS,
    payload: items,
  };
};

export const addTodoItemError = (error) => {
  return {
    type: TODO_ADD_ITEM_ERROR,
    payload: error,
  };
};

// Selected To-Do Items Change
export const selectedTodoItemsChange = (selectedItems) => {
  return {
    type: TODO_SELECTED_ITEMS_CHANGE,
    payload: selectedItems,
  };
};

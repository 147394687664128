import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { servicePath } from 'constants/defaultValues';
import {
  TODO_GET_LIST,
  TODO_ADD_ITEM,
  TICKETS_RESPONSES_GET_LIST,
  TIKETE_RESPONSE_ITEM,
  CATEYGORYS_GET_LIST,
  CATEYGORY_ADD_ITEM,
} from '../contants';

import {
  getTodoListSuccess,
  getTodoListError,
  addTodoItemSuccess,
  addTodoItemError,
  getTicketsResponseSuccess,
  getTicketsResponseError,
  addTicketResponseError,
  addTicketResponseSuccess,
  getCateygorysSuccess,
  getCateygorysError,
  addcategorySuccess,
  addcategoryError,
} from './actions';

const getAuthState = (state) => state.authUser.currentUser;

const getTodoListRequest = async (role, uid, token) => {
  try {
    const api =
      role === 1
        ? `${servicePath}/tickets/getAllForAdmin`
        : `${servicePath}/tickets/getAll/${uid}`;
    const response = await axios.get(api, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

function* getTodoListItems() {
  const { token, uid, role } = yield select(getAuthState); // Get token and uid from auth state
  const response = yield call(getTodoListRequest, role, uid, token);
  if (response.response?.data?.errorMessage) {
    yield put(getTodoListError(response.response?.data?.errorMessage));
  } else {
    yield put(getTodoListSuccess(response));
  }
}

// Selector to get all todo items from Redux state
const getAllTodoItems = (state) => state.todoApp.allTodoItems;

const addTodoItemRequest = async (
  item,
  allTodoItems,
  uid,
  token,
  userName,
  userEmail
) => {
  try {
    const response = await axios.post(
      `${servicePath}/tickets/add/${uid}`,
      { ...item, userName, userEmail },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    if (allTodoItems.length > 0) {
      allTodoItems.splice(0, 0, response.data.ticket);
    }

    return response.data.ticket;
  } catch (error) {
    throw new Error(
      error.response?.data?.errorDetails || 'Add new ticket request failed'
    );
  }
};

function* addTodoItem({ payload }) {
  try {
    const allTodoItems = yield select(getAllTodoItems);
    const { token, uid, name, email } = yield select(getAuthState);
    const response = yield call(
      addTodoItemRequest,
      payload,
      allTodoItems,
      uid,
      token,
      name,
      email
    );
    yield put(addTodoItemSuccess(response));
    yield call(getTodoListItems);
  } catch (error) {
    yield put(addTodoItemError(error.message));
  }
}

const getTicketsResponseListRequest = async (ticketId, token) => {
  try {
    const response = await axios.get(
      `${servicePath}/ticketsResponses/getAll/${ticketId}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.errorDetails || 'error');
  }
};

function* getTicketsResponseItems({ payload }) {
  const { item, history } = payload;
  try {
    const { token } = yield select(getAuthState);
    const response = yield call(getTicketsResponseListRequest, item, token);
    yield put(getTicketsResponseSuccess(response));
  } catch (error) {
    history.goBack();
    yield put(getTicketsResponseError(error));
  }
}

const getCategorysListRequest = async (token) => {
  try {
    const response = await axios.get(`${servicePath}/category/getAll`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

function* getCategorysItems() {
  try {
    const { token } = yield select(getAuthState);
    const response = yield call(getCategorysListRequest, token);
    yield put(getCateygorysSuccess(response));
  } catch (error) {
    yield put(getCateygorysError(error));
  }
}

const addCategoryRequest = async (item, uid, token) => {
  try {
    const response = await axios.post(
      `${servicePath}/category/add/${item}/${uid}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.category;
  } catch (error) {
    throw new Error(
      error.response?.data?.errorDetails || 'Add new category request failed'
    );
  }
};

function* addCategoryItem({ payload }) {
  try {
    const { token, uid } = yield select(getAuthState);
    const response = yield call(addCategoryRequest, payload, uid, token);
    yield put(addcategorySuccess(response));
    yield call(getTodoListItems);
  } catch (error) {
    yield put(addcategoryError(error.message));
  }
}

const addTicketResponseRequest = async (
  item,
  uid,
  token,
  userName,
  userEmail,
  role
) => {
  try {
    const response = await axios.post(
      `${servicePath}/ticketsResponses/add/${uid}`,
      { ...item, userName, userEmail, role },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.responses;
  } catch (error) {
    throw new Error(
      error.response?.data?.errorDetails || 'Add new ticket request failed'
    );
  }
};

function* addTicketResponseItem({ payload }) {
  try {
    const { token, uid, name, email, role } = yield select(getAuthState);
    const response = yield call(
      addTicketResponseRequest,
      payload,
      uid,
      token,
      name,
      email,
      role
    );
    yield put(addTicketResponseSuccess(response));
    yield call(getTodoListItems);
  } catch (error) {
    yield put(addTicketResponseError(error.message));
  }
}

export function* watchGetList() {
  yield takeEvery(TODO_GET_LIST, getTodoListItems);
}

export function* watchGetTicketsResponseList() {
  yield takeEvery(TICKETS_RESPONSES_GET_LIST, getTicketsResponseItems);
}

export function* watchGetgetCategorysList() {
  yield takeEvery(CATEYGORYS_GET_LIST, getCategorysItems);
}

export function* watchAddItem() {
  yield takeEvery(TODO_ADD_ITEM, addTodoItem);
}

export function* watchAddTicketResponse() {
  yield takeEvery(TIKETE_RESPONSE_ITEM, addTicketResponseItem);
}

export function* watchAddCategory() {
  yield takeEvery(CATEYGORY_ADD_ITEM, addCategoryItem);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetTicketsResponseList),
    fork(watchGetgetCategorysList),
    fork(watchAddItem),
    fork(watchAddTicketResponse),
    fork(watchAddCategory),
  ]);
}

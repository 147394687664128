import {
  TODO_GET_LIST,
  TODO_GET_LIST_SUCCESS,
  TODO_GET_LIST_ERROR,
  TODO_GET_LIST_WITH_FILTER,
  TODO_GET_LIST_WITH_ORDER,
  TODO_GET_LIST_SEARCH,
  TODO_ADD_ITEM,
  TODO_ADD_ITEM_SUCCESS,
  TODO_ADD_ITEM_ERROR,
  TODO_SELECTED_ITEMS_CHANGE,
  TICKETS_RESPONSES_GET_LIST,
  TICKETS_GET_RESPONSES_LIST_SUCCESS,
  TICKETS_GET_RESPONSES_LIST_ERROR,
  TIKETE_RESPONSE_ITEM,
  TIKETE_RESPONSE_ITEM_SUCCESS,
  TIKETE_RESPONSE_ITEM_ERROR,
  CATEYGORYS_GET_LIST,
  CATEYGORYS_GET_LIST_LIST_SUCCESS,
  CATEYGORYS_GET_LIST_LIST_ERROR,
  CATEYGORY_ADD_ITEM,
  CATEYGORY_ADD_SUCCESS,
  CATEYGORY_ADD_ERROR,
} from '../contants';

const INIT_STATE = {
  allTodoItems: [],
  todoItems: [],
  allTicketsResponses: null,
  TicketsResponses: null,
  currentUserTikete: null,
  error: '',
  filter: {
    column: 'etat',
    value: 'Open',
  },
  searchKeyword: '',
  orderColumn: null,
  loaded: false,
  labels: [
    { label: 'EDUCATION', color: 'secondary' },
    { label: 'NEW FRAMEWORK', color: 'primary' },
    { label: 'PERSONAL', color: 'info' },
  ],
  orderColumns: [
    { column: 'title', label: 'Title' },
    { column: 'category', label: 'Category' },
    { column: 'status', label: 'Status' },
  ],
  categories: [],
  selectedItems: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TODO_GET_LIST:
      return { ...state, loaded: false };
    case TICKETS_RESPONSES_GET_LIST:
      return { ...state, loaded: false };
    case CATEYGORYS_GET_LIST:
      return { ...state, loaded: false };

    case TODO_GET_LIST_SUCCESS: {
      const sortedData = action.payload.sort(
        (a, b) => new Date(b.dateadd) - new Date(a.dateadd)
      );
      return {
        ...state,
        loaded: true,
        allTodoItems: sortedData,
        todoItems: sortedData,
      };
    }
    case TICKETS_GET_RESPONSES_LIST_SUCCESS: {
      const sortedData = action.payload.responses?.sort(
        (a, b) => new Date(a.dateadd) - new Date(b.dateadd)
      );
      return {
        ...state,
        loaded: true,
        allTicketsResponses: sortedData,
        TicketsResponses: sortedData,
        currentUserTikete: action.payload.ticket,
      };
    }
    case CATEYGORYS_GET_LIST_LIST_SUCCESS:
      return {
        ...state,
        loaded: true,
        categories: action.payload,
      };

    case TODO_GET_LIST_ERROR:
      return {
        ...state,
        loaded: true,
        error: action.payload,
        categories: state.categories,
      };
    case TICKETS_GET_RESPONSES_LIST_ERROR:
      return { ...state, loaded: true, error: action.payload };
    case CATEYGORYS_GET_LIST_LIST_ERROR:
      return { ...state, loaded: true, error: action.payload };

    case TODO_GET_LIST_WITH_FILTER:
      if (action.payload.column === '' || action.payload.value === '') {
        return {
          ...state,
          loaded: true,
          todoItems: state.allTodoItems,
          filter: null,
        };
      }
      if (action.payload.column === 'etat' && action.payload.value === 'Open') {
        const filteredItems = state.allTodoItems?.filter(
          (item) => item.etat !== 'Solved' && item.etat !== 'Closed'
        );
        return {
          ...state,
          loaded: true,
          todoItems: filteredItems,
          filter: null,
        };
      }
      // eslint-disable-next-line no-case-declarations
      const filteredItems = state.allTodoItems?.filter(
        (item) => item[action.payload.column] === action.payload.value
      );
      return {
        ...state,
        loaded: true,
        todoItems: filteredItems,
        filter: {
          column: action.payload.column,
          value: action.payload.value,
        },
      };

    case TODO_GET_LIST_WITH_ORDER:
      if (action.payload === '') {
        return {
          ...state,
          loaded: true,
          todoItems: state.todoItems,
          orderColumn: null,
        };
      }
      // eslint-disable-next-line no-case-declarations
      const sortedItems = state.todoItems.sort((a, b) => {
        if (a[action.payload] < b[action.payload]) return -1;
        if (a[action.payload] > b[action.payload]) return 1;
        return 0;
      });
      return {
        ...state,
        loaded: true,
        todoItems: sortedItems,
        orderColumn: state.orderColumns.find(
          (x) => x.column === action.payload
        ),
      };

    case TODO_GET_LIST_SEARCH:
      if (action.payload === '') {
        return { ...state, todoItems: state.allTodoItems };
      }
      // eslint-disable-next-line no-case-declarations
      const keyword = action.payload.toLowerCase();
      // eslint-disable-next-line no-case-declarations
      const searchItems = state.allTodoItems.filter(
        (item) =>
          item.title.toLowerCase().indexOf(keyword) > -1 ||
          item.detail.toLowerCase().indexOf(keyword) > -1 ||
          item.status.toLowerCase().indexOf(keyword) > -1 ||
          item.category.toLowerCase().indexOf(keyword) > -1 ||
          item.label.toLowerCase().indexOf(keyword) > -1
      );
      return {
        ...state,
        loaded: true,
        todoItems: searchItems,
        searchKeyword: action.payload,
      };

    case TODO_ADD_ITEM:
      return { ...state, loaded: false };

    case TODO_ADD_ITEM_SUCCESS:
      return {
        ...state,
        loaded: true,
        allTodoItems: action.payload,
        todoItems: action.payload,
      };

    case TODO_ADD_ITEM_ERROR:
      return { ...state, loaded: true, error: action.payload };
    //
    case TIKETE_RESPONSE_ITEM:
      return { ...state, loaded: false };

    case TIKETE_RESPONSE_ITEM_SUCCESS: {
      const sortedData = action.payload?.sort(
        (a, b) => new Date(a.dateadd) - new Date(b.dateadd)
      );
      return {
        ...state,
        loaded: true,
        allTicketsResponses: sortedData,
        TicketsResponses: sortedData,
      };
    }

    case TIKETE_RESPONSE_ITEM_ERROR:
      return { ...state, loaded: true, error: action.payload };
    //
    //
    case CATEYGORY_ADD_ITEM:
      return { ...state, loaded: false };

    case CATEYGORY_ADD_SUCCESS:
      return {
        ...state,
        loaded: true,
        categories: action.payload,
      };

    case CATEYGORY_ADD_ERROR:
      return { ...state, loaded: true, error: action.payload };
    //
    case TODO_SELECTED_ITEMS_CHANGE:
      return { ...state, loaded: true, selectedItems: action.payload };
    default:
      return { ...state };
  }
};

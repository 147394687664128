import {
  ASSISTANCE_GET_LIST,
  ASSISTANCE_GET_LIST_SUCCESS,
  ASSISTANCE_GET_LIST_ERROR,
  ASSISTANCE_GET_LIST_WITH_FILTER,
  ASSISTANCE_GET_LIST_WITH_ORDER,
  ASSISTANCE_GET_LIST_SEARCH,
  ASSISTANCE_ADD_ITEM,
  ASSISTANCE_ADD_ITEM_SUCCESS,
  ASSISTANCE_ADD_ITEM_ERROR,
  ASSISTANCE_SELECTED_ITEMS_CHANGE,
  ASSISTANCE_RESPONSES_GET_LIST,
  ASSISTANCE_ADD_RESPONSE_ITEM,
  ASSISTANCE_ADD_RESPONSE_ITEM_SUCCESS,
  ASSISTANCE_ADD_RESPONSE_ITEM_ERROR,
  ASSISTANCE_GET_RESPONSES_LIST_ERROR,
  ASSISTANCE_GET_RESPONSES_LIST_SUCCESS,
} from '../contants';

export const getAssistanceList = () => {
  return {
    type: ASSISTANCE_GET_LIST,
  };
};

export const getAssistanceListSuccess = (items) => {
  return {
    type: ASSISTANCE_GET_LIST_SUCCESS,
    payload: items,
  };
};

export const getAssistanceListError = (error) => {
  return {
    type: ASSISTANCE_GET_LIST_ERROR,
    payload: error,
  };
};

// Assistance   responses actions
export const assistanceResponseList = (item, history) => {
  return {
    type: ASSISTANCE_RESPONSES_GET_LIST,
    payload: { item, history },
  };
};

export const getAssistanceResponseSuccess = (items) => {
  return {
    type: ASSISTANCE_GET_RESPONSES_LIST_SUCCESS,
    payload: items,
  };
};

export const getAssistanceResponseError = (error) => {
  return {
    type: ASSISTANCE_GET_RESPONSES_LIST_ERROR,
    payload: error,
  };
};

// Add Assistance Response
export const addAssistancetResponseItem = (item) => {
  return {
    type: ASSISTANCE_ADD_RESPONSE_ITEM,
    payload: item,
  };
};

export const addAssistanceResponseSuccess = (items) => {
  return {
    type: ASSISTANCE_ADD_RESPONSE_ITEM_SUCCESS,
    payload: items,
  };
};

export const addAssistanceResponseError = (error) => {
  return {
    type: ASSISTANCE_ADD_RESPONSE_ITEM_ERROR,
    payload: error,
  };
};

// Filtered To-Do List
export const getAssistanceListWithFilter = (column, value) => {
  return {
    type: ASSISTANCE_GET_LIST_WITH_FILTER,
    payload: { column, value },
  };
};

export const getAssistanceListWithOrder = (column) => {
  return {
    type: ASSISTANCE_GET_LIST_WITH_ORDER,
    payload: column,
  };
};

export const getAssistanceListSearch = (keyword) => {
  return {
    type: ASSISTANCE_GET_LIST_SEARCH,
    payload: keyword,
  };
};

// Add To-Do Item
export const addAssistanceItem = (item) => {
  return {
    type: ASSISTANCE_ADD_ITEM,
    payload: item,
  };
};

export const addAssistanceItemSuccess = (items) => {
  return {
    type: ASSISTANCE_ADD_ITEM_SUCCESS,
    payload: items,
  };
};

export const addAssistanceItemError = (error) => {
  return {
    type: ASSISTANCE_ADD_ITEM_ERROR,
    payload: error,
  };
};

// Selected To-Do Items Change
export const selectedAssistanceItemsChange = (selectedItems) => {
  return {
    type: ASSISTANCE_SELECTED_ITEMS_CHANGE,
    payload: selectedItems,
  };
};

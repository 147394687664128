import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { servicePath } from 'constants/defaultValues';
import {
  ASSISTANCE_GET_LIST,
  ASSISTANCE_ADD_ITEM,
  ASSISTANCE_RESPONSES_GET_LIST,
  ASSISTANCE_ADD_RESPONSE_ITEM,
} from '../contants';

import {
  getAssistanceListSuccess,
  getAssistanceListError,
  addAssistanceItemSuccess,
  addAssistanceItemError,
  addAssistanceResponseError,
  addAssistanceResponseSuccess,
  getAssistanceResponseSuccess,
  getAssistanceResponseError,
} from './actions';

const getAuthState = (state) => state.authUser.currentUser;

const getAssistanceListRequest = async (role, uid, token) => {
  try {
    const api =
      role === 1
        ? `${servicePath}/assistance/getAllForAdmin`
        : `${servicePath}/assistance/getAll/${uid}`;
    const response = await axios.get(api, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

function* getAssistanceListItems() {
  const { token, uid, role } = yield select(getAuthState); // Get token and uid from auth state
  const response = yield call(getAssistanceListRequest, role, uid, token);
  if (response.response?.data?.errorMessage) {
    yield put(getAssistanceListError(response.response?.data?.errorMessage));
  } else {
    yield put(getAssistanceListSuccess(response));
  }
}

// Selector to get all Assistance items from Redux state
const getAllAssistanceItems = (state) => state.assistanceApp.allAssistanceItems;

const addAssistanceItemRequest = async (
  item,
  allAssistanceItems,
  uid,
  token,
  userName,
  userEmail
) => {
  try {
    const response = await axios.post(
      `${servicePath}/assistance/add/${uid}`,
      { ...item, userName, userEmail, priority: item.priority.value },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    if (allAssistanceItems.length > 0) {
      allAssistanceItems.splice(0, 0, response.data.ticket);
    }

    return response.data.ticket;
  } catch (error) {
    throw new Error(
      error.response?.data?.errorDetails || 'Add new ticket request failed'
    );
  }
};

function* addAssistanceItem({ payload }) {
  try {
    const allAssistanceItems = yield select(getAllAssistanceItems);
    const { token, uid, name, email } = yield select(getAuthState);
    const response = yield call(
      addAssistanceItemRequest,
      payload,
      allAssistanceItems,
      uid,
      token,
      name,
      email
    );
    yield put(addAssistanceItemSuccess(response));
    yield call(getAssistanceListItems);
  } catch (error) {
    yield put(addAssistanceItemError(error.message));
  }
}

const getAssistanceResponseListRequest = async (ticketId, token) => {
  try {
    const response = await axios.get(
      `${servicePath}/assictanceResponces/getAll/${ticketId}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.errorDetails || 'error');
  }
};

function* getAssistanceResponseItems({ payload }) {
  const { item, history } = payload;
  try {
    const { token } = yield select(getAuthState);
    const response = yield call(getAssistanceResponseListRequest, item, token);
    yield put(getAssistanceResponseSuccess(response));
  } catch (error) {
    history.goBack();
    yield put(getAssistanceResponseError(error));
  }
}

const addTicketResponseRequest = async (
  item,
  uid,
  token,
  userName,
  userEmail,
  role
) => {
  try {
    const response = await axios.post(
      `${servicePath}/assictanceResponces/add/${uid}`,
      { ...item, userName, userEmail, role },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.responses;
  } catch (error) {
    throw new Error(
      error.response?.data?.errorDetails || 'Add new ticket request failed'
    );
  }
};

function* addTicketResponseItem({ payload }) {
  try {
    const { token, uid, name, email, role } = yield select(getAuthState);
    const response = yield call(
      addTicketResponseRequest,
      payload,
      uid,
      token,
      name,
      email,
      role
    );
    if (response.success && response.success === false) {
      yield put(addAssistanceResponseError(response.errorDetails || 'error'));
    }
    yield put(addAssistanceResponseSuccess(response));

    // yield call(getAssistanceListItems);
  } catch (error) {
    yield put(addAssistanceResponseError(error.message));
  }
}

export function* watchGetList() {
  yield takeEvery(ASSISTANCE_GET_LIST, getAssistanceListItems);
}

export function* watchGetAssistanceResponseList() {
  yield takeEvery(ASSISTANCE_RESPONSES_GET_LIST, getAssistanceResponseItems);
}

export function* watchAddItem() {
  yield takeEvery(ASSISTANCE_ADD_ITEM, addAssistanceItem);
}

export function* watchAddTicketResponse() {
  yield takeEvery(ASSISTANCE_ADD_RESPONSE_ITEM, addTicketResponseItem);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetAssistanceResponseList),
    fork(watchAddItem),
    fork(watchAddTicketResponse),
  ]);
}

import io from 'socket.io-client';
import { SOCKET_URL } from 'constants/defaultValues';

let socket;

export const InitializeSocket = (token, uid, role) => {
  if (!socket) {
    socket = io(SOCKET_URL, {
      query: { userType: role, userId: uid, userToken: token },
      extraHeaders: { authorization: token },
      reconnectionAttempts: 5, // Limit reconnection attempts
      reconnectionDelay: 3000, // Time between reconnection attempts
    });

    // Event listener for when the socket disconnects
    socket.on('disconnect', (reason) => {
      console.warn('Socket disconnected:', reason);
      if (reason === 'io server disconnect') {
        // If disconnected by the server, reconnect manually
        socket.connect();
      }
    });

    // Event listener for reconnection attempts
    socket.on('reconnect_attempt', (attempt) => {
      console.info(`Reconnection attempt #${attempt}`);
    });

    // Event listener for connection errors
    socket.on('connect_error', (error) => {
      console.error('Connection error:', error);
      // Optionally, trigger a manual reconnection attempt
      setTimeout(() => {
        if (!socket.connected) {
          socket.connect();
        }
      }, 5000); // Retry after 5 seconds
    });

    // Example event listener for a custom event
    // socket.on('receive_message', (data) => {
    //   console.log('receive_message', data);
    // });
  }
  return socket;
};

export const getSocket = () => socket;

// Function to reconnect manually if needed
export const reconnectSocket = () => {
  if (socket && !socket.connected) {
    console.info('Attempting to reconnect to the socket...');
    socket.connect();
  }
};
